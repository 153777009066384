import { makeReducer } from 'redux-utils';
import * as actions from './actions';
import { NAVIGATION } from 'config';

export const getInitialState = () => ({
  navigations: NAVIGATION ? [NAVIGATION] : [],
  editableFolderIdInSiteNavigation: '',
  editableFolderIdInNavigationBlock: '',
  searchText: '',
  navigationSelectedInTheDropdown: '',
  links: {
    isAddingLinkToCustomNavigation: false,
    autoFilledLinkName: '',
  },
  openNavigation: false,
  customNavigationCreationLoading: false,
  areAllNavigationsAlreadyFetched: false,
});

const navigationReducer = makeReducer(actions, getInitialState, (state, action) => {
  switch (action.type) {
    case actions.OPEN_NAVIGATION: {
      return { ...state, openNavigation: action.payload };
    }
    case actions.UPDATE_NAVIGATION: {
      const updatedNavigations = state.navigations.map(navigation => {
        if (navigation._id === action.payload._id) {
          return { ...navigation, ...action.payload };
        }
        return navigation;
      });
      return { ...state, navigations: updatedNavigations };
    }
    case actions.SET_NAVIGATION_SELECTED_IN_THE_DROPDOWN: {
      return { ...state, navigationSelectedInTheDropdown: action.payload };
    }
    case actions.SET_EDITABLE_FOLDER_ID_IN_SITE_NAVIGATION: {
      return { ...state, editableFolderIdInSiteNavigation: action.payload };
    }
    case actions.SET_EDITABLE_FOLDER_ID_IN_NAVIGATION_BLOCK: {
      return { ...state, editableFolderIdInNavigationBlock: action.payload };
    }
    case actions.SET_SEARCH_TEXT: {
      return { ...state, searchText: action.payload };
    }
    case actions.SET_NAVIGATIONS: {
      return { ...state, navigations: action.payload };
    }
    case actions.SET_CREATE_LINK_FOR_CUSTOM_NAVIGATION: {
      const { isAddingLink, linkName } = action.payload;
      return { ...state, links: { isAddingLinkToCustomNavigation: isAddingLink, autoFilledLinkName: linkName } };
    }
    case actions.CREATE_CUSTOM_NAVIGATION_LOADING: {
      return { ...state, customNavigationCreationLoading: action.payload };
    }
    case actions.FETCH_ALL_NAVIGATIONS: {
      return { ...state, areAllNavigationsAlreadyFetched: true };
    }
    default:
      return state;
  }
});

export type INavigationv2State = ReturnType<typeof getInitialState>;

export default navigationReducer;

