import { call, put, takeEvery } from 'redux-saga/effects';
import {
  MaestroIFrameEvents,
  postMessageToIframeSDK,
  EventSource,
} from 'services/iframe';
import { IFRAME_SDK_URL } from 'config';

import {
  showModal,
  LOGIN_MODAL,
  ILoginModalAction,
} from './actions';
import { ModalKinds } from './types';

export function* loginModalSaga(action: ILoginModalAction) {
  const { payload: { isIframe, page = 'ACCOUNTLOOKUP', provider, email } } = action;
  if (isIframe && IFRAME_SDK_URL === 'iframe') {
    yield call(postMessageToIframeSDK, {
      action: MaestroIFrameEvents.IFRAME_REQUEST_LOGIN,
      eventSource: EventSource.LOGIN_MODAL,
    });
    return;
  }
  yield put(showModal({ kind: ModalKinds.login, data: { page, provider, email } }));
  return;
}

export default function* modalSaga() {
  yield takeEvery<ILoginModalAction>(LOGIN_MODAL, loginModalSaga);
}
