import INavigation, { IExternalLink, INavigationParent } from 'models/INavigation';
import { makeAction } from 'redux-utils';

export const UPDATE_NAVIGATION = 'navigationv2/UPDATE_NAVIGATION';
export const DELETE_NAVIGATION = 'navigationv2/DELETE_NAVIGATION';
export const SET_EDITABLE_FOLDER_ID_IN_SITE_NAVIGATION = 'navigationv2/SET_EDITABLE_FOLDER_ID_IN_SITE_NAVIGATION';
export const SET_EDITABLE_FOLDER_ID_IN_NAVIGATION_BLOCK = 'navigationv2/SET_EDITABLE_FOLDER_ID_IN_NAVIGATION_BLOCK';
export const INCLUDE_DUPLICATED_PAGE_TO_DEFAULT_NAVIGATION = 'navigationv2/INCLUDE_DUPLICATED_PAGE_TO_DEFAULT_NAVIGATION';
export const SET_SEARCH_TEXT = 'navigationv2/SET_SEARCH_TEXT';
export const SET_NAVIGATION_SELECTED_IN_THE_DROPDOWN = 'navigationv2/SET_NAVIGATION_SELECTED_IN_THE_DROPDOWN';
export const SET_NAVIGATION_APPLIED_IN_THE_PAGE = 'navigationv2/SET_NAVIGATION_APPLIED_IN_THE_PAGE';
export const SET_NAVIGATIONS = 'navigationv2/SET_NAVIGATIONS';
export const ADD_LINK_TO_NAVIGATION = 'navigationv2/ADD_LINK_TO_NAVIGATION';
export const REMOVE_LINK_FROM_NAVIGATION = 'navigationv2/REMOVE_LINK_FROM_NAVIGATION';
export const OPEN_NAVIGATION = 'navigationv2/OPEN_NAVIGATION';
export const FETCH_ALL_NAVIGATIONS = 'navigationv2/FETCH_ALL_NAVIGATIONS';
export const REMOVE_ITEM_FROM_NAVIGATIONS = 'navigationv2/REMOVE_ITEM_FROM_NAVIGATIONS';
export const UPDATE_PAGE_IN_NAVIGATIONS = 'navigationv2/UPDATE_PAGE_IN_NAVIGATIONS';

// custom navigations
export const CREATE_CUSTOM_NAVIGATION = 'navigationv2/CREATE_CUSTOM_NAVIGATION';
export const CREATE_CUSTOM_NAVIGATION_LOADING = 'navigationv2/CREATE_CUSTOM_NAVIGATION_LOADING';
export const ADD_ITEM_TO_CUSTOM_NAVIGATION = 'navigationv2/ADD_ITEM_TO_CUSTOM_NAVIGATION';
export const ADD_FOLDER_TO_CUSTOM_NAVIGATION = 'navigationv2/ADD_FOLDER_TO_CUSTOM_NAVIGATION';
export const REMOVE_ITEM_FROM_CUSTOM_NAVIGATION = 'navigationv2/REMOVE_ITEM_FROM_CUSTOM_NAVIGATION';
export const SET_CREATE_LINK_FOR_CUSTOM_NAVIGATION = 'navigationv2/SET_CREATE_LINK_FOR_CUSTOM_NAVIGATION';

export const updateNavigation = makeAction(
  UPDATE_NAVIGATION,
  (navigation: INavigation) => ({ payload: navigation }),
);

export const deleteNavigation = makeAction(
  DELETE_NAVIGATION,
  (navigationId: string) => ({ payload: navigationId }),
);

export const setEditableFolderIdInSiteNavigation = makeAction(
  SET_EDITABLE_FOLDER_ID_IN_SITE_NAVIGATION,
  (editableFolderId: string) => ({ payload: editableFolderId }),
);

export const setEditableFolderIdInNavigationBlock = makeAction(
  SET_EDITABLE_FOLDER_ID_IN_NAVIGATION_BLOCK,
  (editableFolderId: string) => ({ payload: editableFolderId }),
);

export const includeDuplicatedPageToDefaultNavigation = makeAction(
  INCLUDE_DUPLICATED_PAGE_TO_DEFAULT_NAVIGATION,
  (originalPageId: string, clonePage) => ({ payload: { originalPageId, clonePage } }),
);

export const setSearchText = makeAction(
  SET_SEARCH_TEXT,
  (searchText: string) => ({ payload: searchText }),
);

export const setNavigationSelectedInTheDropdown = makeAction(
  SET_NAVIGATION_SELECTED_IN_THE_DROPDOWN,
  (navigationId: string) => ({ payload: navigationId }),
);

export const setNavigationAppliedInThePage = makeAction(
  SET_NAVIGATION_APPLIED_IN_THE_PAGE,
  (navigationId: string, pageId: string) => ({ payload: { navigationId, pageId } }),
);

export const setCreateCustomNavigationLoading = makeAction(
  CREATE_CUSTOM_NAVIGATION_LOADING,
  (loading: boolean) => ({ payload: loading }),
);

export const setNavigations = makeAction(
  SET_NAVIGATIONS,
  (navigations: INavigation[]) => ({ payload: navigations }),
);

export const addLinkToNavigation = makeAction(
  ADD_LINK_TO_NAVIGATION,
  (navigationId: string, link: IExternalLink) => ({ payload: { navigationId, link } }),
);

export const removeLinkFromNavigation = makeAction(
  REMOVE_LINK_FROM_NAVIGATION,
  (navigationId: string, linkId: string) => ({ payload: { navigationId, linkId } }),
);

export const createCustomNavigation = makeAction(
  CREATE_CUSTOM_NAVIGATION,
  ({ navigationToCopyDataFrom, navName }: { navigationToCopyDataFrom?: INavigation, navName?: string }) => (
    { payload: { navigationToCopyDataFrom, navName } }
  ),
);

export const addItemToCustomNavigation = makeAction(
  ADD_ITEM_TO_CUSTOM_NAVIGATION,
  (newParent: INavigationParent, navigationId: string) => ({ payload: { newParent, navigationId } }),
);

export const addFolderToCustomNavigation = makeAction(
  ADD_FOLDER_TO_CUSTOM_NAVIGATION,
  (folderName?: string) => ({ payload: folderName }),
);

export const removeItemFromCustomNavigation = makeAction(
  REMOVE_ITEM_FROM_CUSTOM_NAVIGATION,
  (navigationId: string, itemId: string) => ({ payload: { navigationId, itemId } }),
);

export const setCreateLinkForCustomNavigation = makeAction(
  SET_CREATE_LINK_FOR_CUSTOM_NAVIGATION,
  ({ isAddingLink, linkName }: { isAddingLink: boolean, linkName: string }) => {
    return { payload: { isAddingLink, linkName } };
  },
);

export const toggleNavigation = makeAction(
  OPEN_NAVIGATION,
  (open: boolean) => ({ payload: open }),
);

export const fetchAllNavigations = makeAction(
  FETCH_ALL_NAVIGATIONS,
  () => ({ payload: {} }),
);

export const removeItemFromNavigations = makeAction(
  REMOVE_ITEM_FROM_NAVIGATIONS,
  (itemId: string) => ({ payload: itemId }),
);

export const updatePageInNavigations = makeAction(
  UPDATE_PAGE_IN_NAVIGATIONS,
  (pageId: string, newName: string, newSlug: string, newPrivate?: boolean) => ({ payload: { pageId, newName, newSlug, newPrivate } }),
);
